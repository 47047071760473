<template>
  <div>
    <!-- banner -->
    <div class="banner">
      <div class="bg-filter"></div>
      <div class="text-wrapper">
        <div>公益项目</div>
        <div>PUBLIC WELFARE PROJECTS</div>
      </div>
    </div>
    <!-- content -->
    <div id="home-grid">
      <div class="home-grid">
        <ul>
          <li v-for="(item, index) in newList[activeIndex]" :key="index">
            <div class="img-wrapper">
              <img :src="item.img" alt="" />
              <div class="activity-status" :class="{ ongoing: item.ongoing }">
                <span v-if="item.ongoing">活动进行中</span>
                <span v-else>活动已结束</span>
              </div>
            </div>
            <div class="text-wrapper">
              <div class="activity-title">{{ item.title }}</div>
              <div class="activity-date">{{ item.date }}</div>
              <div class="activity-abstract">{{ item.abstract }}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 分页 -->
    <Pagination
      :pageCount="newList.length"
      :currentPage="activeIndex"
      @prePage="handlePrePage"
      @nextPage="handleNextPage"
      @switchPage="handleSwitchPage($event)"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
export default {
  name: "HomeGrid",
  components: { Pagination },
  data() {
    return {
      currentIndex: 0,
      list: [
        {
          id: 1,
          ongoing: true,
          img: require("@/assets/images/home-new1.jpg"),
          title: "关怀山村留守老人",
          date: "2020-03-28",
          abstract:
            "疫情的迅速袭来，牵动着每一个中国人的心。尽管目前新冠病毒感染人数增长趋于稳定，但......"
        },
        {
          id: 2,
          ongoing: true,
          img: require("@/assets/images/home-new2.jpg"),
          title: "关怀山村留守老人",
          date: "2020-03-28",
          abstract:
            "疫情的迅速袭来，牵动着每一个中国人的心。尽管目前新冠病毒感染人数增长趋于稳定，但......"
        },
        {
          id: 3,
          ongoing: false,
          img: require("@/assets/images/home-new3.jpg"),
          title: "关怀山村留守老人",
          date: "2020-03-28",
          abstract:
            "疫情的迅速袭来，牵动着每一个中国人的心。尽管目前新冠病毒感染人数增长趋于稳定，但......"
        },
        {
          id: 4,
          ongoing: false,
          img: require("@/assets/images/home-new4.jpg"),
          title: "关怀山村留守老人",
          date: "2020-03-28",
          abstract:
            "疫情的迅速袭来，牵动着每一个中国人的心。尽管目前新冠病毒感染人数增长趋于稳定，但......"
        },
        {
          id: 5,
          ongoing: false,
          img: require("@/assets/images/home-new5.jpg"),
          title: "关怀山村留守老人",
          date: "2020-03-28",
          abstract:
            "疫情的迅速袭来，牵动着每一个中国人的心。尽管目前新冠病毒感染人数增长趋于稳定，但......"
        },
        {
          id: 6,
          ongoing: false,
          img: require("@/assets/images/home-new6.jpg"),
          title: "关怀山村留守老人",
          date: "2020-03-28",
          abstract:
            "疫情的迅速袭来，牵动着每一个中国人的心。尽管目前新冠病毒感染人数增长趋于稳定，但......"
        },
        {
          id: 7,
          ongoing: false,
          img: require("@/assets/images/home-new1.jpg"),
          title: "关怀山村留守老人",
          date: "2020-03-28",
          abstract:
            "疫情的迅速袭来，牵动着每一个中国人的心。尽管目前新冠病毒感染人数增长趋于稳定，但......"
        },
        {
          id: 8,
          ongoing: false,
          img: require("@/assets/images/home-new2.jpg"),
          title: "关怀山村留守老人",
          date: "2020-03-28",
          abstract:
            "疫情的迅速袭来，牵动着每一个中国人的心。尽管目前新冠病毒感染人数增长趋于稳定，但......"
        },
        {
          id: 9,
          ongoing: false,
          img: require("@/assets/images/home-new3.jpg"),
          title: "关怀山村留守老人",
          date: "2020-03-28",
          abstract:
            "疫情的迅速袭来，牵动着每一个中国人的心。尽管目前新冠病毒感染人数增长趋于稳定，但......"
        },
        {
          id: 10,
          ongoing: false,
          img: require("@/assets/images/home-new4.jpg"),
          title: "关怀山村留守老人",
          date: "2020-03-28",
          abstract:
            "疫情的迅速袭来，牵动着每一个中国人的心。尽管目前新冠病毒感染人数增长趋于稳定，但......"
        },
        {
          id: 11,
          ongoing: false,
          img: require("@/assets/images/home-new5.jpg"),
          title: "关怀山村留守老人",
          date: "2020-03-28",
          abstract:
            "疫情的迅速袭来，牵动着每一个中国人的心。尽管目前新冠病毒感染人数增长趋于稳定，但......"
        },
        {
          id: 12,
          ongoing: false,
          img: require("@/assets/images/home-new6.jpg"),
          title: "关怀山村留守老人",
          date: "2020-03-28",
          abstract:
            "疫情的迅速袭来，牵动着每一个中国人的心。尽管目前新冠病毒感染人数增长趋于稳定，但......"
        }
      ]
    };
  },
  computed: {
    newList() {
      let newList = [];
      for (let i = 0; i < this.list.length; i += 9) {
        let segment = this.list.slice(i, i + 9);
        newList.push(segment);
      }
      return newList;
    },
    activeIndex() {
      return this.currentIndex;
    }
  },
  methods: {
    handlePrePage() {
      if (this.currentIndex == 0) {
        return;
      }
      this.currentIndex -= 1;
    },
    handleNextPage() {
      if (this.currentIndex == this.newList.length - 1) {
        return;
      }
      this.currentIndex += 1;
    },
    handleSwitchPage(index) {
      this.currentIndex = index;
    }
  }
};
</script>

<style lang="scss" scoped>
.clearfix::after {
  content: "";
  display: block;
  clear: both;
}
.banner {
  background-image: url(../../assets/images/project-banner.jpg);
  background-position: 50% 60%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  color: #fff;
  text-align: center;
  width: 100%;
  height: 590px;
  background-size: cover;
  align-items: center;
  position: relative;
  margin-bottom: 160px;
  // scroll-behavior: smooth;
  // 滤镜
  .bg-filter {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(37, 163, 246, 0.267);
  }
  // 文字
  .text-wrapper {
    z-index: 10;
    div:first-child {
      font-size: 40px;
      margin-bottom: 30px;
    }
    div:last-child {
      font-size: 20px;
    }
  }
}
ul {
  padding: 0;
  width: 1122px;
  height: 1450px;
  margin: 0 auto;
}
li {
  float: left;
  width: 358px;
  height: 420px;
  margin: 75px 8px;
  margin-top: 0;
  transition: all ease 0.3s;
  cursor: pointer;
  &:hover {
    box-shadow: 0 4px 5px 0 #ccc;
  }
  .img-wrapper {
    width: 100%;
    height: 220px;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
    }
    .activity-status {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 90px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      font-size: 12px;
      background-color: #c1c1c1;
      color: #777;
    }
    .activity-status.ongoing {
      background-color: #004898;
      color: #fff;
    }
  }
  .text-wrapper {
    padding: 25px;
    .activity-title {
      font-size: 20px;
    }
    .activity-date {
      margin: 10px 0;
    }
    .activity-abstract {
      font-size: 14px;
      color: #999;
    }
  }
}
</style>
